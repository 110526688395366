// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify";
import { apiCall } from "../../utilities/src/NetworkRequest";

// Customizable Area Start

export interface Library {
  id: string;
  type: string;
  attributes: {
    id: number;
    status: string;
    name: string;
    thumbnail_image: {
      id: string;
      url: string;
    }
    content_type: string;
  }
}

export interface AssignedCourses {
  id: string;
  type: string;
  attributes: {
    id: number;
    content_id: number;
    content_type: string;
    content: AssignedCoursesContent;
  };
}

export interface AssignedCoursesContent {
  id: number,
  title: string,
  status: string,
  thumbnail_image: {
    id: string;
    url: string;
  }
  content_id: number,
  content_type: string
}
  
export interface UserResponse {
  id: string;
  attributes: UserAttributes;
}

export interface UserAttributes {
  id: number;
  first_name: string | null;
  last_name: string | null;
  middle_name: string | null;
  email: string | null;
  phone_number: number;
  country_code: number;
  prefix_title: string | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  // Customizable Area End
}

interface S {
  isCourseActive: string;
  filterItems: boolean;
  searchInputValue: string;
  showList: boolean;
  filteredList: Array<string>;
  libraryData: Library[];
  programData: Library[];
  selectedContent: Library[];
  selectedContentProgramData: Library[],
  checkedData: boolean;
  checkedDataProgram: boolean
  removedProgramData: Library[];
  removedAllData: Library[];
  groupDropdown: boolean;
  selectedGroup: string;
  assigneeDropdown: boolean;
  selectedAssignee: string;
  addButtonClicked: boolean;
  UserData: Array<UserResponse>;
  assignedContentCoursesData: AssignedCourses[];
  assignedContentProgramData: AssignedCourses[];
  userId: number;
  firstName: string;
  lastName: string;
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UserAssignContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCourseListDataCallId: string = "";
  getProgramListDataCallId: string = "";
  createAssignContentCallId: string = "";
  userListApiCallId: string = "";
  getCourseAssignedApiCallId:string = "";
  getProgramsAssignedApiCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isCourseActive: "course",
      showList: false,
      filterItems: true,
      searchInputValue: '',
      filteredList: [],
      removedAllData: [],
      libraryData: [],
      programData: [],
      removedProgramData: [],
      selectedContent: [],
      selectedContentProgramData: [],
      checkedData: false,
      checkedDataProgram: false,
      groupDropdown: false,
      selectedGroup: '',
      assigneeDropdown: false,
      selectedAssignee: '',
      addButtonClicked: false,
      UserData: [],
      assignedContentCoursesData:[],
      assignedContentProgramData:[],
      userId: 0,
      firstName: "",
      lastName: "",

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    let userDataId = this.props?.navigation?.getParam('id')
    this.getUserList(Number(userDataId));
    this.getCourseList()
    this.getProgramList()
    this.getAssigenedCourses(Number(userDataId))
    this.getAssigenedPrograms(Number(userDataId))
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.createAssignContentCallId === apiRequestCallId) {
        this.createAssignContent(from, message, responseJson)
      }
      
      if (apiRequestCallId === this.getCourseAssignedApiCallId) {
        const assignedCoursesData = responseJson?.data;
        this.setState({
            assignedContentCoursesData: assignedCoursesData,
            removedAllData: [
                ...this.state.removedAllData,
                ...assignedCoursesData.map((item: AssignedCourses) => {
                    return {
                        id: item.id,
                        type: item.type,
                        attributes: {
                            id: item.attributes.id,
                            status: item.attributes.content.status,
                            name: item.attributes.content.title,
                            thumbnail_image: item.attributes.content.thumbnail_image.url || null,
                            content_type: item.attributes.content.content_type,
                        }
                    };
                })
            ]
        });
    }

    if (apiRequestCallId === this.getProgramsAssignedApiCallId) {
      const assignedProgramsData = responseJson?.data;
      const allData = [
        ...this.state.assignedContentProgramData,
        ...assignedProgramsData
      ];
      this.setState({ 
        assignedContentProgramData: assignedProgramsData,
        removedAllData: allData.map((item) => {
          return {
            id: item.id,
            type: item.type,
            attributes: {
              id: item.attributes.id,
              status: item.attributes.content.status,
              name: item.attributes.content.title,
              thumbnail_image: item.attributes?.content?.thumbnail_image?.url || null,
              content_type: item.attributes.content.content_type
            }
          };
        })
      });
    }
      if (apiRequestCallId === this.userListApiCallId) {
        this.setState({
          UserData: responseJson.data,
          userId: responseJson.data.attributes.id,
          firstName: responseJson.data.attributes.first_name,
          lastName: responseJson.data.attributes.last_name,
        })
      }
      if (this.getCourseListDataCallId === apiRequestCallId) {
        this.setState({ libraryData: responseJson?.data})
      }

      if (this.getProgramListDataCallId === apiRequestCallId) {
        this.setState({ programData: responseJson?.data})
      }
    }
    // Customizable Area End
  }

  createAssignContent = (from: string, message: Message, responseJson: any) => {
    if (responseJson?.data) {
      toast.success(`Changes Saved!`);
    } else {
      responseJson?.errors.map((err: { message: string }) => {
        toast.error(err.message);
      });
    }
  };

  handleToggleCourses = () => {
    this.setState({
      isCourseActive: "course",
      searchInputValue: '',
    }, () => {
    })
  };

  handleTogglePrograms = () => {
    this.setState({
      isCourseActive: "program",
      searchInputValue: '',
    }, () => {
    })
  };

  handleCheckboxChange = (data: Library) => {
    let selectedData;
    if (this.state.selectedContent.includes(data)) {
      selectedData = this.state.selectedContent.filter((library) => library.id !== data.id);
    } else {
      selectedData = [...this.state.selectedContent, data];
    }
    this.setState({ selectedContent: selectedData });
  };

  handleRemoveCheckboxChangeProgram = (data: Library) => {
    let selectedData;
    if (this.state.removedProgramData.includes(data)) {
      selectedData = this.state.removedProgramData.filter((library) => library.id !== data.id);
    } else {
      selectedData = [...this.state.removedProgramData, data];
    }
    this.setState({ removedProgramData: selectedData });
  };

  handleAddButtonClick = () => {
    if (this.state.selectedContent.length > 0) {
      const newContent = this.state.selectedContent.filter(
        (selectedItem) => 
          !this.state.removedAllData.some((existingItem) => existingItem.id === selectedItem.id)
      );
  
      this.setState((prevState) => ({
        removedAllData: [...prevState.removedAllData, ...newContent],
        checkedData: true,
        addButtonClicked: true,
      }));
    }
  };

  handleRemoveButtonClickProgram = () => {
    if (this.state.removedAllData.length > 0) {
      const filteredData = this.state.removedAllData.filter((item: Library) =>
        !this.state.removedProgramData.includes(item)
      )
      const courseData = this.state.removedProgramData.filter((filter) => this.state.libraryData.includes(filter))
      const programData = this.state.removedProgramData.filter((filter) => this.state.programData.includes(filter))
      this.setState({
        programData: [...this.state.programData, ...programData],
        libraryData: [...this.state.libraryData, ...courseData],
        removedAllData: filteredData, selectedContentProgramData: filteredData, selectedContent: filteredData
      });
      if (filteredData.length === 0) {
        this.setState({
          checkedDataProgram: false,
          addButtonClicked: false
        });
      }
    }

  };

  isCheckedValue = (arrayData: Library[], value: Library) => {
    return arrayData.includes(value)
  }


  getCourseList = async () => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseListDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listCourseDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getProgramList = async () => {
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProgramListDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listProgramDataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  groupdropdownOpen = (event: any) => {
    this.setState({
      groupDropdown: !this.state.groupDropdown,
    })
  }

  handleGroupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedGroup: event.target.value, groupDropdown: false });
  };

  assigneedropdownOpen = () => {
    this.setState({
      assigneeDropdown: !this.state.assigneeDropdown,
    })
  }

  handleAssigneeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedAssignee: event.target.value, assigneeDropdown: false });
  };

  getAssigenedCourses = async (userId: number) => {
    this.getCourseAssignedApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.assignedCoursesLists}user_id=${userId}&user_type=AccountBlock::Account`,
    });
  };

  getAssigenedPrograms = async (userId: number) => {
    this.getProgramsAssignedApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.assignedProgramsLists}user_id=${userId}&user_type=AccountBlock::Account`,
    });
  };

  getUserList = async (userId: number) => {
    this.userListApiCallId = await apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.userListEndPoint}/${userId}`,
    });
  };

  handleNextPageNavigation = () => {

  }

  postUserAssign = async () => {
    let userData = this.props?.navigation?.getParam('id')
    const token = await localStorage.getItem("token")
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: token
    };


    const tempData = this.state.removedAllData.map((data) => {
      const type = data.attributes.content_type;
      return {
        content_id: data.id,
        content_type: `${type}`
      };
    })

    const httpBody = {
      user: {
        user_id: userData,
        user_type: "AccountBlock::Account",
        user_contents_attributes: tempData
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAssignContentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createUserAssignContentEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
}
// Customizable Area End