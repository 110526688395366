// Customizable Area Start
import React from "react";
import { Typography, Box, Paper } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SelectDropdown from "../../../components/src/SelectDropdown";
import CourseCard from "./CourseCard";
import Anchor from "./Anchor";
import { useStatStyles } from "./Statistics";

const COURSE_STATUS_OPTIONS = [
  { id: 1, label: "In Progress", value: "in_progress" },
  { id: 2, label: "Newly Added", value: "pending" },
  { id: 3, label: "Completed", value: "completed" },
];

interface Props extends RouteComponentProps {
  isLoading: boolean;
  courses: any;
  courseStatus: any;
  handleCourseStatusChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

function Courses({
  isLoading,
  courses,
  courseStatus,
  history,
  handleCourseStatusChange,
}: Props) {
  const {
    flex,
    contentContainer,
    coursesContainer,
    noCoursesContainer,
    paper,
    headingContainer,
    heading,
    noCourseText,
    skeletonContainer,
  } = useStatStyles();

  const handleNavigation = (courseUrl: string, id: string) => {
    history.push(courseUrl, { name: id });
  }

  return (
    <Paper className={paper}>
      <Box className={`${flex} ${headingContainer}`}>
        <Typography className={heading}>Courses</Typography>
        <SelectDropdown
          options={COURSE_STATUS_OPTIONS}
          currentValue={courseStatus}
          handleChange={handleCourseStatusChange}
        />
      </Box>
      {isLoading && (
        <Box className={skeletonContainer}>
          <Skeleton variant="text" />
          <Skeleton variant="text" width={200} />
        </Box>
      )}
      {!isLoading && courses.length === 0 && (
        <Box className={`${flex} ${contentContainer} ${noCoursesContainer}`}>
          <Typography align="center" className={noCourseText}>
            No course found
          </Typography>
        </Box>
      )}
      {!isLoading &&
        courses.length > 0 &&
        courses.map((course: any, index: number) => {
          const {
            attributes: {
              collection: {
                id,
                attributes: { title, expand_type},
              },
            },
          } = course;

          let courseUrl = '';
          if (expand_type === "Course") {
            courseUrl = `/course-detail/${expand_type}/${id}/${title.replace(/\s/g, "-")}`;
          }else if (expand_type === "Program") {
            courseUrl = `/program-detail/${expand_type}/${id}/${title.replace(/\s/g, "-")}`;
          }

          return (
            <Box
              key={course.id}
              id="handle-nav-courses"
              className={`${flex} ${contentContainer} ${coursesContainer}`}
              style={{
                borderTop: index === 0 ? "1px solid #F1F1F5" : "none", // 1st child
              }}
              onClick={() => handleNavigation(courseUrl, id)}
            >
              <CourseCard key={course.id} course={course} />
            </Box>
          );
        })}
      <Anchor to="/personal" text="view all" />
    </Paper>
  );
}

export default withRouter(Courses);
// Customizable Area End