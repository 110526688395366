import React from "react";
// Customizable Area Start
import Greetings from "./Greetings";
import Statistics from "./Statistics";
import DashboardCarousel from "../../../components/src/DashboardCarousel";
import Courses from "./Courses";
import RemindersWeb from "./Reminders.web";
import './Dashboard.css';
import { Box, Typography, styled } from "@material-ui/core";
import CustomCarousel from "../../../components/src/CustomCarousel/CustomCarousel";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderAdvertisement = (itemsToShow:number) => {
    return (
      this.state.advertismentData.map((slide:any, index:number) => (
        <div
          className="carousel-item"
          key={index}
          onClick={()=> this.handleNavigateTOCourse(slide.attributes.button_link)}
          title={slide.attributes.title.length>20 && slide.attributes.title}
        >
          <img src={slide.attributes.web_image.url} alt={`Slide ${index}`} />
          <div className="carousel-text">
              <h2 style={{ fontSize: '24px', fontWeight: 600 }}>
            {
              slide.attributes.title.length>20 ? (
                `${slide.attributes.title.slice(0,20)}...`
              ) :(
                slide.attributes.title
              )
            }
              </h2>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
            <p style={{ fontSize: '16px', fontWeight: 600, width:"100%"}}>
              {slide.attributes.caption}
            </p>
            <ViewMoreText>View More</ViewMoreText>
            </div>
          </div>
        </div>
      ))
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      courseStatus,
      courses,
      statTimeFrame,
      keyStatistics: {
        timeWatched,
        totalTimeWatched,
        completedRewards,
        totalRewards,
        courseOverallProgress,
      },
    } = this.state;

    const slicedCourses = courses.slice(0, 4);
    return (
      <>
        <Greetings />
        <Box className="carouselBox" style={{
          background: "linear-gradient(268.92deg, #FFFFFF 0%, #F0F3F6 100%)",
          padding:"24px",borderRadius:"16px"
        }}>
        <CustomCarousel
              data-test-id="sliderComponentId"
              datavalue={this.state.advertismentData}
                children={this.renderAdvertisement}
                getTransformValue={this.getTransformValue}
              />
              </Box>
        <Statistics
          timeFrame={statTimeFrame}
          timeWatched={timeWatched}
          totalTimeWatched={totalTimeWatched}
          completedRewards={completedRewards}
          totalRewards={totalRewards}
          courseOverallProgress={courseOverallProgress}
          handleStatTimeFrameChange={this.handleStatTimeFrameChange}
        />

        <Courses
          isLoading={this.state.loading}
          courseStatus={courseStatus}
          courses={slicedCourses}
          handleCourseStatusChange={this.handleCourseStatusChange}
        />
        <RemindersWeb
          userRole="user"
          fullScreen={this.props.fullScreen}
          navigation={this.props.navigation}
        />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ViewMoreText = styled(Typography)({
  background: "linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)",
  width: "150px",
  height: "48px",
  padding: "8px 16px 8px 16px",
  gap: "10px",
  borderRadius: "88px",
  fontWeight:600,
  fontSize:"16px",
  color:"white",
  cursor:"pointer",
  textAlign:"center",
  display:"flex",justifyContent:"center",alignItems:"center",
  "@media (max-width:960px)": {
    fontSize:"12px",
  },
})
// Customizable Area End
