Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.candidateslabelTitleText = "Candidates";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboardContentType = "application/json";
exports.dashboardApiMethodType = "GET";
exports.libraryApiMethodTypePost = "POST";
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.notificationIcon = "../assets/ic_password_invisible.png";
exports.messageIcon = "../assets/ic_password_invisible.png";
exports.userIcon = "../assets/ic_password_invisible.png";
exports.libraryUrl = "bx_block_library2/libraries";
exports.mychannel = "bx_block_usergroups/channels/my_channel";
exports.xpandCollectionList = "bx_block_coursecreation/collections";
exports.personalLibrarynewlyAddedCourses = "bx_block_library2/personal_libraries?course_status=pending";
exports.personalLibraryCompletedCourses="bx_block_library2/personal_libraries?course_status=completed";
exports.personalLibraryProgressCourses="bx_block_library2/personal_libraries?course_status=in_progress";
exports.addToCart = "/bx_block_shopping_cart/cart"
exports.cartItem = "bx_block_shopping_cart/cart?full_details=true"
exports.addToList = "/bx_block_library2/enroll_courses"
exports.coursesGetUrl = "bx_block_library2/personal_libraries";
exports.keyStatisticsGetUrl = "bx_block_coursecreation/key_statistics";
exports.remindersGetUrl = "bx_block_coursecreation/due_date_courses";
exports.adminRemindersGetUrl = "bx_block_admin/popup";
exports.dismissRemindersPostUrl = "bx_block_coursecreation/close_popup";
// Customizable Area End