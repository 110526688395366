import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from "yup";
import { toast } from "react-toastify";



export interface UserData{
        id: string,
        type: string,
        attributes: {
           id:number,
            first_name:string,
            last_name: string,
            status: string
        }
}
export const  validationSchema = yup.object().shape({
  TeamName: yup
    .string()
    .required("Team Name is required")
    .min(5, "min 5 characters required")
    .max(50, "max 50 characters allowed"),
})

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  userList:Array<UserData>;
  allExperts:any;
  anchorEl:boolean;
  selectRadio:string;
  removedAllDatas: any;
  removedAllDataProgram: any;
  selectedContent:any;
  removedUserData:any;
  checkedDataProgram:any;
  checkedItem:boolean;
  UserData:any;
  selectedContentUserData:any;
  placeholderSearchText:string;
  editTeamId:string;
  InitailRemoveData:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateTeamController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListCallId:string="";
  expertDataApiCallIds:string="";
  createTeamApiCallId:string="";
  formikRef:  any;
  getShowDataApiCallId:string="";
  UpdateApicallID:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      userList:[],
      allExperts:[],
      anchorEl:false,
      selectRadio:"",
      removedAllDatas: [],
      removedAllDataProgram:[],
      selectedContent:[],
      removedUserData:[],
      checkedDataProgram:[],
      checkedItem:false,
      UserData:[],
      selectedContentUserData:[],
      placeholderSearchText:"",
      editTeamId:this.props.navigation.getParam("id"),
      InitailRemoveData:[]
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "From: " + this.state.txtSavedValue + " To: " + value,
        "Change Value"
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

if (apiRequestCallId === this.expertDataApiCallIds) {
  this.setState({ allExperts: responseJson.data })
  this.parseApiCatchErrorResponse(errorResponse);
  }
  if (apiRequestCallId === this.getUserListCallId) {
    this.setState({ UserData: responseJson?.data })
    this.parseApiCatchErrorResponse(errorResponse);
    }
    if (apiRequestCallId === this.createTeamApiCallId){
      this.CreateApiRes(responseJson)
    }
    if(apiRequestCallId === this.getShowDataApiCallId){
      this.getTeamListData(responseJson);
    }
    if(apiRequestCallId === this.UpdateApicallID){
      this.UpdateTeamResponse(responseJson)
    }
    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {

    super.componentDidMount();
     this.getExpertSearchListNews();
    this.getUserList()
    this.getShowTeamData()
  }
  ConstructEndPoints = () => {
    let params = [];
    if (this.state.editTeamId) {
      params.push(this.IdValueParams());
    }
    if(this.state.placeholderSearchText){
      params.push(this.seacrhValueParam());
    }
    return params.length > 0 ? params.join('&') : '';
  };
  private IdValueParams(): any{
    return `id=${this.state.editTeamId?this.state.editTeamId:""}`;
  }
  private  seacrhValueParam(): any{
    return `search=${this.state.placeholderSearchText?this.state.placeholderSearchText:""}`;
  }
 
  getUserList=()=>{
    const header = {
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getUserListCallId = requestMessage.messageId
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UserTeamApiEndPoint}?${this.ConstructEndPoints()}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  CreateApiRes=(responseJSON:any)=>{
     const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "TeamBuilder");
  message.addData(getName(MessageEnum.NavigationPayLoadMessage),{showSnackbar:true});
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
  }
  dropdownHandlerOpen = (event:any) => {
    this.setState({
      anchorEl:!this.state.anchorEl,

    })
  }
  handleCreateTeamDisable=(values:any)=>{
 if(values.TeamName && values.expert_id && this.state.removedAllDatas.length){
  return true
 }else{
  return false
 }
  }
  backColor = (value: any) => {
    return value ?"#fff": "#FCF6F6"
  }
  TextColor=(value: any)=>{
    return value?"#1C1B1B":"#ADAAAA"
  }
  dropdownHandlerClose = () => {
    this.setState({
      anchorEl: false,
    })
  }
  handleRadioChange=(event:any)=>{
    this.formikRef?.setFieldValue("expert_id",event?.target.value)
  }
  handleApplyBtn=()=>{
    this.setState({
      anchorEl: false,
    })
  }
  handleAddButtonClick = () => {
    if (this.state.selectedContent.length > 0) {
      this.setState({ removedAllDatas: this.state.selectedContent})

    }

  }
  handlePlaceholderSearchChanges=(event:any)=>{
    this.setState({placeholderSearchText:event.target.value},()=>{
      this.getUserList()
    })
    }
  isCheckedValue = (arrayData:any, value: any) => {
    return arrayData.includes(value)
  }
  UpdateTeamResponse=(responseJSON:any)=>{
    if(responseJSON.data){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "TeamBuilder");
      message.addData(getName(MessageEnum.NavigationPayLoadMessage),{showTeamToast:responseJSON.data.attributes.name});
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }else{
      toast.error(responseJSON.error,{autoClose:2000})
    }
 
  }
  handleRemoveButtonClickProgram = () => {
    if (this.state.removedAllDatas.length > 0) {
      const filteredData = this.state.removedAllDatas.filter((item:any) =>
        !this.state.removedUserData.includes(item)
      )
      const UserDatas = this.state.removedUserData.filter((filter:any)=> this.state.UserData.includes(filter))
      const uniqueUserDatas = UserDatas.filter((newUser: any) =>
        !this.state.UserData.some((user: any) => user.id === newUser.id)
      );
      this.setState({
        UserData: [...this.state.UserData, ...uniqueUserDatas],
        removedAllDatas: filteredData,
        selectedContent:[],removedUserData:[]
      });
      if (filteredData.length === 0) {
        this.setState({
          checkedDataProgram: false
        });
      }
    }

  };
  handleCheckboxChange = (data: any) => {
    let selectedData;
    if (this.state.selectedContent.includes(data)) {
      selectedData = this.state.selectedContent.filter((library:any) => library.id !== data.id);
    } else {
      selectedData = [...this.state.selectedContent, data];
    }
    this.setState({ selectedContent: selectedData });
  };

  handleRemoveCheckboxChangeProgram = (data: any) => {
    let selectedData;
    if (this.state.removedUserData.includes(data)) {
      selectedData = this.state.removedUserData.filter((library:any) => library.id !== data.id);
    } else {
      selectedData = [...this.state.removedUserData, data];
    }
    this.setState({ removedUserData: selectedData });
  };
  
 CreateTeamApi = (values:any) => {
  const teamsAccountsAttributes:any = [];
 this.state.removedAllDatas.forEach((account:any) => {
    teamsAccountsAttributes.push({
      account_id: account.id,
      account_type: "AccountBlock::Account"
    });
  });
  const body={
      "team": {
         "name":values.TeamName,
         "expert_id": values.expert_id,
         "teams_accounts_attributes": teamsAccountsAttributes         
      }

  }
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createTeamApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TeamApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  getExpertSearchListNews = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.expertDataApiCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_library2/experts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTeamListData=(responseJSON:any)=>{
    const userEditData=responseJSON.data.attributes.users;
    const ids=userEditData.map((i:any)=>i.id)
    this.setState({removedAllDatas:userEditData,selectedContent:userEditData,InitailRemoveData:ids})
    this.formikRef?.setFieldValue("TeamName",responseJSON.data.attributes.name)
    this.formikRef?.setFieldValue("expert_id",responseJSON.data.attributes.manager_id)

  }
  UpdateData=(values:any,teamsAccountsAttributes:any)=>{
    const id: any[] = [];
    const RemoveNewTeam = this.state.removedAllDatas.map((i: any) => i.id);
      const missingIds = this.state.InitailRemoveData.filter((id: any) => !RemoveNewTeam.includes(id));
      const addingIds = RemoveNewTeam.filter((id: any) => !this.state.InitailRemoveData.includes(id));
  if (missingIds.length) {
    missingIds.forEach((element: any) => {
      teamsAccountsAttributes.push({
        id: element,
        "_destroy": true
      });
    });
  }
  if (addingIds.length) {
    addingIds.forEach((account: any) => {
      teamsAccountsAttributes.push({
        account_id: account,
        account_type: "AccountBlock::Account"
      });
    });
  }
  return teamsAccountsAttributes;
  }
  updateTeamData = (values: any) => {
    const teamsAccountsAttributes: any[] = [];
    this.UpdateData(values,teamsAccountsAttributes);
    const body = {
      team: {
        name: values.TeamName,
        expert_id: values.expert_id,
        teams_accounts_attributes: teamsAccountsAttributes,
      }
    };
    const token = localStorage.getItem('token');
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.UpdateApicallID = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TeamApiEndPoint}/${this.state.editTeamId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  
  getShowTeamData = () => {
    const token = localStorage.getItem('token')
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShowDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TeamApiEndPoint}/${this.state.editTeamId}/show_team`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
